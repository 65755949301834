////
/// @group error.scss
////


// Config
// ================================
	@import 'config';


// Фикс mqpacker
// ================================
	@include mqPacker;


// Normalize
// ================================

	@include normalize__document;
	@include normalize__sections;
	// @include normalize__grouping-content;
	// @include normalize__text-level-semantics;
	// @include normalize__embedded-content;
	// @include normalize__forms;
	// @include normalize__interactive;
	// @include normalize__scripting;
	// @include normalize__hidden;

	// @include normalize__text-level-semantics__psevdo-classes;
	// @include normalize__forms__psevdo-classes;
	// @include normalize__addons;



// Import Partials
// ================================
	@import '_partials/base';



// Custom css
// ================================
	.error-view {
		width: 100%;
		height: 100%;
		text-align: center;
		background: #eee;

		&__block {
			text-align: left;
			width: 90%;
			max-width: 580px;
			margin: 1rem auto;
		}

		&__code {
			font-weight: bold;
			font-size: 8rem;
			margin-right: 2rem;
			line-height: .92;
			text-align: right;

			@include media( $lg ) {
				font-size: 12rem;
			}
		}
	}

	.error-view__msg {
		margin-top: -2em;
		background-color: #fff;
		box-shadow: 0 3px 10px rgba(#000, .2);
		padding: 1em 2em;
		line-height: 1.4;

		@include media( $lg ) {
			margin-top: -3rem;
			padding: 2rem;
			line-height: 1.4;
		}

		ul, ol, p {
			margin: .65em 0;
		}

		ul, ol {
			padding-left: 1.5rem;
		}

		small {
			display: inline;
		}
	}

